<div class="main-flex-container">
  <div class="queues-tab-list">
    <span *ngIf="!queuesLoaded" class="loader-v-center"><i class="fa fa-spinner fa-pulse fa-fw fa-5x"></i></span>
    <h3>Select queue</h3>
    <app-view-board-queue-list
      *ngIf="queuesLoaded"
      [queues]="queues"
      [selectedQueue]="selectedQueue"
      (onQueueClick)="toggleQueueSelection($event)"
    ></app-view-board-queue-list>
  </div>
  <div class="token-list-container">
    <h3>People in queue</h3>
    <app-view-board-token-list [tokens]="tokens"></app-view-board-token-list>
  </div>
  <div class="counter-list">
    <span *ngIf="!consolesLoaded" class="loader-v-center"><i class="fa fa-spinner fa-pulse fa-fw fa-5x"></i></span>

    <div class="counter-list-header">
      <h3>Counters</h3>
      <div class="flex">
        <div class="counter-list-stats">
          <span class="counter-list-stats-label">Served</span>
          <i class="served-stats-icon"></i>
          {{ countPeopleServed() }}
        </div>
        <div class="counter-list-stats">
          <span class="counter-list-stats-label">No show</span>
          <i class="abandon-stats-icon"></i>
          {{ totalAbandons || 0 }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="consolesLoaded">
      <div class="console-detail">
        <!-- Show Consoles -->
        <app-counter-console
          *ngFor="let id of selectedCounters"
          [counter]="getCounterById(id)"
          [requestLocked]="requestLocked"
          [branchQueue]="selectedQueue"
          (alertMessage)="handleApiError($event)"
          (removeCounterChange)="removeCounter($event)"
          (countPeopleServed)="countPeopleServed($event)"
          (countAbandonments)="countAbandonments($event)"
          (requestLockedChange)="handleRequestLockChange($event)"
        ></app-counter-console>

        <!-- New Console -->
        <div class="view-board-add-new" (click)="addCounterClick()">
          <div>+</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-modal (onVisibleChange)="handleCountersModalChange($event)" *ngIf="showCountersModal" width="50%" height="fit-content">
  <h2 class="modal-counter-title">Select counter</h2>
  <div class="modal-counter-list">
    <ng-container *ngFor="let counter of queueCounters">
      <button *ngIf="!selectedCounters?.includes(counter.id)" (click)="handleCounterClick(counter)">
        {{ counter.counterName }}
      </button>
    </ng-container>
  </div>
  <p *ngIf="queueCounters?.length == 0" class="no-counter-available">No other counter available</p>
</app-modal>
